<template>
  <div class="main">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>企业信息</el-breadcrumb-item>
    </el-breadcrumb> -->

    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
        <div>
          <a-form-model
              ref="rulesSystemForm"
              :model="companyInfo"
              :rules="systemRules"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 16 }"
          >
            <a-row :gutter="20" type="flex" justify="center">
              <a-col :span="13">
                <a-form-model-item label="企业名称" prop="customName">
                  <a-input v-model="companyInfo.customName" placeholder="请输入企业名称"/>
                </a-form-model-item>
                <a-form-model-item label="企业位置" prop="address">
                  <a-input v-model="companyInfo.address" placeholder="请输入企业位置"/>
                </a-form-model-item>
                <a-form-model-item label="企业电话" prop="contact">
                  <a-input v-model="companyInfo.contact" placeholder="请输入企业电话"/>
                </a-form-model-item>
                <a-form-model-item label="企业简介" prop="introduction">
                  <a-textarea
                      v-model="companyInfo.introduction"
                      placeholder="请填写企业简介"
                      :auto-size="{ minRows: 2, maxRows: 6 }"
                  />
                </a-form-model-item>
                <a-form-model-item label="营业执照" prop="businessLicenseImgPath" >
                  <div>
                  <a-spin :spinning="spinning">
                  <a-upload-dragger
                      name="file"
                      :multiple="false"
                      :action="httpUploadSrc"
                      :showUploadList="false"
                      :beforeUpload="handleBeforeUpload"
                      @change="handleChange"
                  >

                    <div v-if="!isShowLogo">
                      <p class="ant-upload-drag-icon">
                        <a-icon type="inbox"/>
                      </p>
                      <p class="ant-upload-text">点击上传图片</p>
                    </div>
                    <div v-else>
                      <img
                          v-if="companyInfo.businessLicenseImgPathAll"
                          :src="companyInfo.businessLicenseImgPathAll"
                           width="100%" alt=""
                      />
                    </div>
                  </a-upload-dragger>
                  </a-spin>
                  </div>
                </a-form-model-item>
                <a-form-model-item label="操作" v-auth="'companyInfo-save'">
                  <a-button type="primary" @click="handleSave()">保存</a-button>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </el-card>
  </div>
</template>

<script>
export default {
  name: "SystemSetting",
  data() {
    return {
      spinning:true,
      //图片上传地址
      httpUploadSrc: this._axios_env.SERVER+`/file/img?access_token=${localStorage.getItem('access_token').substring(6,localStorage.getItem('access_token').length)}`,
      //企业信息对象
      companyInfo: {
        id:'',
        customName: "",
        address: "",
        contact: "",
        introduction: "",
        businessLicenseImgPath: "",
        businessLicenseImgPathAll: "",
      },
      //表单验证规则
      systemRules: {
        key: [
          {required: true, message: '请填写网站域名', trigger: 'blur'},
        ],
        webTitle: [
          {required: true, message: '请填写登录标题', trigger: 'blur'},
        ],
        menuTitle: [
          {required: true, message: '请填写菜单标题', trigger: 'blur'},
        ],
        webCopyright: [
          {required: true, message: '请填写版权声明', trigger: 'blur'},
        ],
        loginLogoAddress: [
          {required: true, message: '请上传logo', trigger: 'blur'},
        ],
      },
    }
  },

  computed: {
    //判断是否显示Logo
    isShowLogo() {
      if (this.companyInfo.businessLicenseImgPath === "" || this.companyInfo.businessLicenseImgPath === null) {
        return false;
      } else {
        var reg = /null/;
        return !reg.test(this.companyInfo.businessLicenseImgPath)
      }
    }
  },

  mounted() {
    this.getCurrentCompanyInfo()
  },

  methods: {
    //获取当前网站设置
    async getCurrentCompanyInfo() {
      const {data: res} = await this.http.organization.getUserOrganizationMsg();
      if (res.status === 200) {
        this.companyInfo = res.data;
      } else {
        this.$message.error(res.msg);
      }
      this.spinning =false;
    },

    //上传文件之前的钩子
    handleBeforeUpload(file, fileList) {
      const reg = /\.(png|jpg|jpeg|gif)$/ig;
      const filename = file.name
      if (reg.test(filename) === false) {
        this.$message.error('请上传正确的图片格式！')
        return false;
      }
    },

    //上传文件改变时的状态
    handleChange({file, fileList, event}) {
      if (file['response'] !== undefined) {
        this.companyInfo.businessLicenseImgPathAll = file['response'].data.allPath;
        this.companyInfo.businessLicenseImgPath = file['response'].data.path;
      }
    },

    //保存
    handleSave() {
      this.$refs.rulesSystemForm.validate(async valid => {
        if (valid) {
          const {data: res} = await this.http.organization.updateUserOrganizationMsg(this.companyInfo);
          if (res.status === 200) {
            this.$message.success(res.msg);
            this.getCurrentCompanyInfo()
          } else {
            this.$message.error(res.msg);
          }
        } else {
          return false
        }
      });
    },
  },
}
</script>

<style scoped>

</style>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
    overflow-y: auto;
  }
}
</style>
